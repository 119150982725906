@font-face { font-family: 'Lato'; src: url('../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */ src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */ url('../fonts/Lato-Regular.ttf') format('truetype'); font-style: normal; font-weight: 400 /* normal*/; text-rendering: optimizeLegibility; }
@font-face { font-family: 'Lato'; src: url('../fonts/Lato-Bold.eot'); /* IE9 Compat Modes */ src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */ url('../fonts/Lato-Bold.ttf') format('truetype'); font-style: normal; font-weight: 700 /* normal*/; text-rendering: optimizeLegibility; }

@libs-dir:		'../libs';

@import '@{libs-dir}/bootstrap/less/bootstrap';
@import (less) '@{libs-dir}/famfamfam/famfamfam-flags.min.css';
@import '@{libs-dir}/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox';

@import (less) '@{libs-dir}/lightgallery/css/lightgallery.css';
@import (less) '@{libs-dir}/plyr/plyr.css';

@font-family-icon: 'Glyphicons Halflings';
@fa-var-check: "\e013";

@brand-primary:						#254aa5;

@headings-color:					@brand-primary;

@font-family-sans-serif:			Lato, Arial, sans-serif;
@font-size-base:					15px;

//@border-radius-base:				0;

//@nav-link-hover-bg:					transparent;
@nav-tabs-border-color:				transparent;
@nav-tabs-active-link-hover-border-color:	@gray-lighter;

@navbar-default-bg:					transparent;
@navbar-default-link-hover-bg:		darken(#fff, 6.5%);
@navbar-default-link-active-bg:		darken(#fff, 6.5%);

@navbar-inverse-color:				#fff;
@navbar-inverse-bg:					@brand-primary;
@navbar-inverse-link-color:			#fff;
@navbar-inverse-link-hover-bg:		darken(@navbar-inverse-bg, 3%);

@well-bg:                     @gray-lighter;
@well-border:                 @gray-lighter;


.navbar-default { min-height: 33px;
	.navbar-nav > li > a { padding-top: 5px; padding-bottom: 5px;
		&:hover { }
	}
}

.nav-tabs > li.active > a { background: @gray-lighter;
	&:hover, &:focus { background: @gray-lighter; }
}

.form-controls-stacked { position: relative; z-index: 0;
	.form-control { z-index: 0; }
	.btn-block { position: absolute; width: auto; top: 0; left: 0; right: 0; margin: 0; }
	.btn-primary { z-index: 2; }
	.btn-default { z-index: 1; }

	input:valid + .btn-primary { display: none; }
}

.panel { background: @gray-lighter; border-color: @gray-lighter;
	.panel-body { padding: @grid-gutter-width;
		h3 { position: relative; margin-top: 0; margin-bottom: 20px; padding-left: 40px;
			&:before { content: attr(data-number); position: absolute; left: 0; width: 40px; }
		}
		.radio { margin-top: 11px; margin-bottom: 11px;
			input { cursor: pointer; }
			label { padding-left: 20px;
				&:before { top: 2px; cursor: pointer; }
				&:after { top: 5px; }
			}
			&:last-child { margin-bottom: 0; }
		}
		.radio-primary input[type="radio"]:checked + label { color: @brand-primary; }

		.row:last-child .form-group { margin-bottom: 0; }
	}
	.panel-footer { background: #fff; border-top: 0 none; }
}

.well { box-shadow: none; }

.footer { color: @text-muted;
	svg { fill: @text-muted; transition: fill .35s;
		&:hover { fill: darken(@text-muted, 20%); }
	}
}

.gallery { font-size: 0;
	a { display: inline-block; margin-right: @grid-gutter-width; margin-bottom: @grid-gutter-width; }
}

.map { width: 100%; height: 400px; }

.radio-style-variant(@color) {
	label {
		&:before { border-color: @color; }
		&:after {background: @color; }
	}
}
.radio-style--3 { .radio-style-variant(#e30613); }
.radio-style--2 { .radio-style-variant(#ec6446); }
.radio-style--1 { .radio-style-variant(#f39200); }
.radio-style-0 { .radio-style-variant(#bfbfbf); }
.radio-style-1 { .radio-style-variant(#83d0f5); }
.radio-style-2 { .radio-style-variant(#00b9ee); }
.radio-style-3 { .radio-style-variant(#0069b4); }
.radio-style- { .radio-style-variant(#535353); }